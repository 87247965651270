import axios from "axios";
import getConfig from "next/config";
import { showToastError } from "../../components/toastNotification/ToastNotification";
import { MESSAGE_API_ERROR } from "../../constants/CommonConstants";

const { publicRuntimeConfig = {} } = getConfig() || {};

const _authorizationHeaders = () => ({
  "Content-Type": "application/json",
});

const handleError = (err, url) => {
  // eslint-disable-next-line no-console
  console.error(`Api call error in services -> request.js : ${url} `, err);
};

export const sendBaseUrl = () => {
  return publicRuntimeConfig?.NEXT_PUBLISH_API_URL_GENERIC;
};

export const sendBlogUrl = () => {
  return publicRuntimeConfig?.NEXT_BLOGS_API;
};

export const sendBtnBaseUrl = () => {
  return publicRuntimeConfig?.NEXT_PUBLISH_APP_URL;
};

export const trimmedBaseUrl = () => {
  const { NEXT_PUBLISH_API_URL = "" } = publicRuntimeConfig;
  return NEXT_PUBLISH_API_URL.replace("api/v1/web/en/delivery", "");
};

export const getNextApiUrl = () => {
  return publicRuntimeConfig?.NEXT_API_URL;
};

export const getCurrentLang = () => {
  const split = location.pathname.split("/");
  return split[1] || "en";
};

export const sendUsersBaseUrl = () => {
  return publicRuntimeConfig?.NEXT_USER_SERVICE_APP_URL;
};

export const getRequest = async (url, _, headers = _authorizationHeaders()) => {
  try {
    const res = await axios.get(publicRuntimeConfig?.NEXT_PUBLISH_API_URL + url, {
      headers: { ...headers },
    });
    return res.data.data ? res.data.data : res.data;
  } catch (err: any) {
    handleError(err, url);
    const statusCode = err?.response?.status || 0;
    showToastError(MESSAGE_API_ERROR, statusCode);
    return err?.response?.data ? err.response.data : {};
  }
};

export const Api = {
  getRequest,
};
