import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import getConfig from "next/config";

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "none",
  },
};
const { publicRuntimeConfig = {} } = getConfig() || {};
const link = createHttpLink({
  uri: publicRuntimeConfig.NEXT_GRAPHQL_URI,
  credentials: "include",
});

const getUpdateLanguageHeader = (lang: string, host: string) => {
  const isLocalhost = host.includes("localhost");
  const hostName = isLocalhost ? publicRuntimeConfig.NEXT_SITE_HOST : host;
  const updateLanguageHeader = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        language: lang,
        site_host: hostName,
        sitehost: hostName,
      },
    };
  });

  return updateLanguageHeader;
};

export const getGraphQlInstance = (lang: string, host: string) => {
  const graphqlInstance = new ApolloClient({
    link: getUpdateLanguageHeader(lang, host).concat(link),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });
  return graphqlInstance;
};

export default getGraphQlInstance;
